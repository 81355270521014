// src/components/DayPass.tsx

import { AuthProvider } from "src/context/AuthContext";
import OfferBody from "./OfferBody";

type Props = {
  lng: string;
};

const OfferWrapper = ({ lng }: Props) => {
  return (
    <AuthProvider>
      <OfferBody lng={lng} />
    </AuthProvider>
  );
};

export default OfferWrapper;
