import { useEffect, useState } from "react";
import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import Spinner from "../global/Spinner";
import { useAuth } from "src/context/AuthContext";
import { fetchOffer } from "src/service/cmsservice";
import type { OfferType } from "src/types/cms/offerType";

type Props = {
  lng: string;
};

const OfferBody = ({ lng }: Props) => {
  const [t, setT] = useState(() => (key: string) => key);
  const [i18nLoaded, setI18nLoaded] = useState(false); // Track i18next loading status
  const { getToken } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [offer, setOffer] = useState<OfferType | null>(null);
  const [isOfferActive, setOfferActive] = useState<boolean>(false);
  // i18n lang switcher
  useEffect(() => {
    const loadTranslations = async () => {
      await i18next.use(HttpBackend).init({
        lng,
        fallbackLng: "de",
        debug: false,
        backend: {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      });

      setT(() => i18next.t.bind(i18next));
      setI18nLoaded(true);
    };

    loadTranslations();
  }, [lng]);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      // console.log("User token found, initiating verification...");
      try {
        const offerR = await fetchOffer(lng, getToken);
        if (offerR) {
          console.log("loaded private office page");
          setOffer(offerR);
        } else {
          console.log("not loaded private office page");
          setOffer(null);
        }
      } catch (error) {
        console.error("Private office fetching failed:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (i18nLoaded) initialize();
  }, [i18nLoaded, lng, getToken]);

  // Set `isOfferActive` whenever `offer` changes
  useEffect(() => {
    if (offer) {
      setOfferActive(
        offer.attributes?.active && new Date(offer.attributes.activeUntil) >= new Date()
      );
    } else {
      setOfferActive(false);
    }
  }, [offer]);

  if (!i18nLoaded || isLoading) {
    return (
      <div className="w-full h-14 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {isOfferActive ? (
        <div className="w-full bg-red-600 text-white flex justify-center">
          <div className="md:inline-flex justify-center items-center">
            <div>
              <span className="font-bold">{offer.attributes.title}</span>
              <span className="mx-2">-</span>
              <span className="text-lg">{offer.attributes.description} </span>
              <span className="align-top text-base">*</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default OfferBody;
